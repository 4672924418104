<template>
  <div class="bac">
    <div class="main">
      <div class="head">
        <div>
          <a>收到的赞</a>
          <i></i>
        </div>
      </div>
      <div class="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fans"
}
</script>

<style scoped>

.bac{
  width: 1920px;
  /*height: 1080px;*/
  padding-left: 298px;
  background-color: #f5f5f5;
}

.main{
  float: left;
  width: 1458px;
  height: 764px;
  margin-left: 66px;
  margin-top: 72px;
  background-color: #ffffff;
  border: 1px solid transparent;
}

.main .head{
  width: 1400px;
  height: 96px;
  margin-left: 30px;
}

.head div{
  width: 122px;
  height: 96px;
  /*font-size: 28px;*/
  /*color: #ec414d;*/
}

.head div a{
  display: block;
  width: 122px;
  height: 94px;
  line-height: 96px;
  font-size: 28px;
  color: #ec414d;
  text-align: center;
}

.head div i{
  display: block;
  width: 122px;
  height: 2px;
  background-color: #ec414d;
}

.main .content{
  width: 1458px;
  height: 668px;
  background: url("../../../../images/blank_box.png") no-repeat center;
}

</style>